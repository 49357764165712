<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('menu.category')}` }}</v-card-title>
        <v-card-text>
          <CategoriesList />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CategoriesList from './form/CategoriesList.vue'

export default {
  components: {
    CategoriesList,
  },
}
</script>
